.body {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.inputField {
  align-items: start;
  justify-content: start;
  display: flex;
  background-color: #15181f;
  outline: 0px solid transparent;
  width: 20rem;
  border-radius: none;
  border: var(--custom-border);
  padding: 0.75rem 1rem;
  margin-top: 5px;
  font-size: 14px;
  color: white;
  font-family: "Montserrat";
  font-weight: "800";
  font-size: "14px";
}

.inputField:hover {
  background-color: #15181f;
  color: white;
  outline: 0px solid transparent;
}

.inputField:focus {
  background-color: #15181f;
  color: white;
  outline: 0px solid transparent;
}

.button23 {
  color: white;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 800;
  border: 0px solid transparent;
  background-color: #6c47ff;
  padding: 0.75rem 1.5rem;
  margin-top: 1.5rem;
  width: 22rem;
}

.button23:hover {
  cursor: pointer;
}
