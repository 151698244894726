.collection {
  padding-top: 5rem;
  display: grid;
  justify-content: center
}

.form-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 1rem;
}

.formitem {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formbutton {
  display: flex;
  justify-content: center;
  margin: 1rem 0rem 0rem 0rem;
}

.select {
  background-color: #15181f;
  outline: 0px solid transparent;
  width: 32rem;
  border: 0px solid white;
  padding: 0.6rem 1rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-family: Montserrat;
  color: white;
}

.input {
  width: 30rem;
  background-color: #15181f;
  outline: 0px solid transparent;
  border: 0px solid white;
  padding: 0.6rem 1rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-family: Montserrat;
  color: white;
}

.input:hover {
  background-color: #15181f;
  outline: 0px solid transparent;
}

.input:focus {
  background-color: #15181f;
  outline: 0px solid transparent;
}

.button {
  color: white;
  font-family: Montserrat;
  font-size: 0.9rem;
  font-weight: 800;
  border: 0px solid transparent;
  background-color: #6c47ff;
  padding: 0.75rem 5rem;
  width: 32rem;
}

.button:hover {
  cursor: pointer;
}

.button2 {
  color: white;
  font-family: Montserrat;
  font-size: 0.9rem;
  font-weight: 800;
  border: 0px solid transparent;
  background-color: #6c47ff;
  padding: 0.75rem 1rem;
  margin: 1rem 0rem 0rem 0rem;
  width: 10rem;
}

.button2:hover {
  cursor: pointer;
}

